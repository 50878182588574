import Layout from '@components/layout/Layout';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import HighlightsList from '@components/ui/highlights-list/HighlightsList';
import TitleBar from '@components/ui/title-bar/TitleBar';
import LanguageProvider from '@lib/language.provider';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const OchronaDanychOsobowychPage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	const data = [
		t`Kompleksowa pomoc prawna w zakresie ochrony danych osobowych.`,
		t`Przygotowywanie dokumentacji w obszarze ochrony danych osobowych.`,
		t`Wsparcie w przygotowywaniu procedur i procesów związanych z obszarem ochrony danych osobowych.`,
		t`Przygotowywanie i opiniowanie umów powierzenia przetwarzania danych osobowych.`,
		t`Pomoc we wdrażaniu wszelkich niezbędnych zmian w organizacji wynikających z wymogów ochrony danych osobowych.`,
		t`Analizy dotyczące zgodności z prawem przetwarzania danych osobowych, w tym opiniowanie wszelkich dokumentów wewnętrznych związanych z przetwarzaniem danych osobowych (takich jak np. polityka bezpieczeństwa itp.)`
	];

	const highlights = [
		{
			label: t`Doradztwo`,
			description: t`we wdrożeniu zasad związanych z realizacją RODO w Multimedia Polska S.A.`
		},
		{
			label: t`Ponad 30 `,
			description: t`umów powierzenia przetwarzania danych osobowych.`
		}
	];

	return (
		<LanguageProvider>
			<Layout>
				<main>
					<title>
						<Trans>Ochrona danych osobowych</Trans>
					</title>
					<Header location={location.pathname} />
					<div className="flex flex-col min-h-screen">
						<TitleBar>
							<div className="relative z-10 pt-10 w-full text-white md:max-w-md lg:max-w-2xl md:pr-10 lg:pr-20">
								<div className="text-sm uppercase">
									<Trans>Specjalizacje</Trans>
								</div>
								<h1 className="font-serif text-4xl font-bold">
									<Trans>Ochrona danych osobowych</Trans>
								</h1>
							</div>
							<div className="absolute top-0 left-0 w-full h-full">
								<StaticImage
									className="block object-cover w-full h-full"
									src="../../assets/images/fuzje-i-przejecia-photo-01.jpg"
									alt={t`Kontakt`}
									placeholder={'none'}
								/>
							</div>
						</TitleBar>
						<div>
							<Container>
								<div className="grid gap-8 lg:gap-16 py-8 md:grid-cols-2 md:py-12">
									<div className="text-gray-dark">
										<h2 className="mb-3 font-bold">Zakres usług</h2>
										<HighlightsList items={data} />
									</div>
									<div>
										<div className="grid gap-2 lg:gap-3">
											{highlights.map((item) => (
												<div className="p-4 lg:p-6 border border-gray-medium">
													<div className="w-full">
														<div className="mb-3 text-2xl font-bold">
															{item.label}
														</div>
														<div className="text-gray-dark text-sm">
															{item.description}
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</Container>
						</div>
						<Footer />
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default OchronaDanychOsobowychPage;
